<template>
  <b-modal ref="linkModal" hide-footer title="Link Product" centered lazy @hide="hideModal" size="md">
    <b-form-group label="Products" label-for="products" label-class="form-label">
      <v-select
        id="type"
        class="form-control v-select-custom"
        label="product_title"
        v-model="products"
        :reduce="(pro) => pro.id"
        multiple
        placeholder="Select Product "
        :options="productOptions"
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!products" v-bind="attributes" v-on="events" />
        </template>
      </v-select>
      <b-form-invalid-feedback :state="!$v.products.required && $v.products.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <div class="d-flex justify-content-end">
      <b-btn
        variant="primary"
        class="ml-2"
        style="width: 100px"
        :disabled="isLoading"
        @click.prevent="submitData"
        href="#"
        data-toggle="modal"
      >
        <span><i class="fas fa-link mr-2"></i>Link</span></b-btn
      >
    </div>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import vSelect from 'vue-select';
export default {
  name: 'LinkModal',
  components: { vSelect },

  props: {
    showModal: { type: Boolean, default: false },
    productOptions: {
      type: Array,
      default() {
        return [];
      },
    },
    program: { type: Number, required: true },
  },

  data() {
    return {
      products: [],
      isLoading: false,
    };
  },
  validations() {
    return {
      products: { required },
    };
  },
  methods: {
    ...mapActions('products', ['linkProdWithProgram', 'getAllProducts']),
    resetData() {
      this.products = null;
    },

    async submitData() {
      this.isLoading = true;
      try {
        await this.linkProdWithProgram({ program: Number(this.program), products: this.products });
        this.makeToast({ variant: 'success', msg: 'Product Linked Successfully!' });
        this.successfullyLinked();
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
    successfullyLinked() {
      this.$refs.linkModal.hide();
      this.$emit('successfull');
    },
    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.linkModal.hide();
      this.hideModal();
    },
  },
  watch: {
    showModal(value) {
      if (value) {
        this.$refs.linkModal.show();

        this.resetData();
      }
    },
  },
};
</script>

<style>
</style>