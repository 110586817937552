<template>
  <b-modal
    ref="registeredStudentsModal"
    hide-footer
    title="Are you sure?"
    centered
    lazy
    size="md"
    scrollable
    @hide="hideModal"
  >
    <p>Are you sure you want to cancel the registration?</p>

    <b-form @submit.prevent="onSubmit">
      <b-form-group label="Cancellation Reason" label-for="cancellation_reason" label-class="form-label">
        <b-form-textarea
          id="cancellation_reason"
          maxlength="250"
          placeholder="Enter reason."
          :rows="4"
          required
          v-model="reason"
        />
      </b-form-group>

      <div class="row">
        <div class="ml-auto" style="margin-right: 12px">
          <b-button @click.prevent="hideModalManual(true)" size="sm">Cancel</b-button>
          <b-button type="submit" variant="danger" size="sm" class="ml-2">Confirm</b-button>
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { get } from 'lodash';
import { REGISTRATION_TYPES } from '@/common/constants';
export default {
  name: 'RegStatusModal',

  props: {
    showModal: { type: Boolean, default: false },
    registration: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      reason: '',
      isLoading: false,
      REGISTRATION_TYPES,
    };
  },

  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        this.isLoading = true;

        this.$refs.registeredStudentsModal.show();
      }
    },
  },

  methods: {
    ...mapActions('student', ['updateStdRegStatus']),
    get,

    hideModal(no = false) {
      this.reason = '';
      this.$emit('close', no);
    },

    hideModalManual(no = false) {
      this.$refs.registeredStudentsModal.hide();
      this.hideModal(no);
    },

    async onSubmit() {
      const data = {
        status: REGISTRATION_TYPES.CANCELLED,
        cancellation_reason: this.reason,
        student_registration: this.registration.id,
      };

      try {
        await this.updateStdRegStatus(data);
        this.hideModalManual();
      } catch (e) {
        //
      }
    },
  },

  async mounted() {},
};
</script>
