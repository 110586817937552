<template>
  <div :class="containerClass">
    <page-header :title="email.title" :container-class="null" class="mb-16pt" />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label="Subject" label-for="subject" label-class="form-label">
        <b-form-input id="subject" placeholder="" v-model="email.subject" disabled />
      </b-form-group>
      <div class="row" v-if="email.type === EMAIL_TYPES.RECURRING">
        <b-form-group label="Time" label-for="time" label-class="form-label" class="col-md-6">
          <b-form-input id="subject" placeholder="" v-model="email.time" disabled />
        </b-form-group>

        <b-form-group label="Day" label-for="day" label-class="form-label" class="col-md-6">
          <b-form-input id="subject" placeholder="" v-model="email.day" disabled />
        </b-form-group>
      </div>

      <b-card>
        <div class="text-center">
          <img :src="getLoggedInUser.linked_entity.logo_url" alt="" height="60px" width="60px" />
        </div>
        <div v-html="replaceSchoolName(email.body)"></div>

        <b-table head-variant="" class="table-nowrap mt-3 mb-2" v-if="email.items" :items="email.items"></b-table>
        <div v-html="replaceSchoolName(email.afterTable)"></div>
      </b-card>
    </b-form>
  </div>
</template>

<script>
import Page from '@/components/Page.vue';
import { EMAIL_TYPES, SYSTEM_EMAILS } from '@/common/constants';
import { mapGetters } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
export default {
  name: 'SystemEmails',
  extends: Page,

  components: { PageHeader },

  data() {
    return {
      title: 'Email Configuration',
      EMAIL_TYPES,
      email: {},
      SYSTEM_EMAILS,
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },
  methods: {
    getEmail() {
      this.email = this.SYSTEM_EMAILS.filter((email) => email.id == this.$route.params.id)[0];
    },
    replaceSchoolName(text) {
      return text && text.includes('{{school_name}}')
        ? text.replace('{{school_name}}', this.getLoggedInUser.linked_entity.name)
        : text;
    },
  },

  async mounted() {
    this.getEmail();
  },
};
</script>

<style>
</style>
