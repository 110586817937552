<template>
  <div :class="containerClass">
    <b-skeleton-wrapper :loading="isEmailLoading">
      <template #loading>
        <b-skeleton width="80%" type="text" height="20%" class="mt-5 mb-3"></b-skeleton>
      </template>

      <page-header :title="emailTitle" :container-class="null" class="mb-16pt" />
    </b-skeleton-wrapper>
    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <div class="d-flex justify-content-end">
        <b-form-group label-class="form-label">
          <b-form-checkbox
            v-model="enabled"
            name="check-button"
            switch
            :disabled="isEmailLoading || disableFields"
            label-position="right"
          >
            Enabled?
          </b-form-checkbox>
        </b-form-group>
      </div>
      <b-form-group label="Subject" label-for="subject" label-class="form-label">
        <b-form-input
          id="subject"
          placeholder="Enter subject for email"
          v-model="subject"
          required
          :disabled="isEmailLoading || disableFields"
        />
      </b-form-group>
      <div class="row" v-if="type === EMAIL_TYPES.RECURRING">
        <b-form-group label-for="time" label-class="form-label" class="col-md-6">
          <label class="form-label">
            Time
            <md-icon
              style="font-size: 16px; color: grey"
              class="svg-icon mr-0"
              v-b-popover.hover.right
              title="This time is in ET Timezone"
              >info</md-icon
            >
          </label>
          <date-picker
            v-model="time"
            type="time"
            value-type="HH:mm a"
            format="hh:mm a"
            class="w-100"
            lang="en"
            :minute-step="15"
            :disabled="disableFields"
          />
        </b-form-group>

        <b-form-group label="Day" label-for="day" label-class="form-label" class="col-md-6">
          <v-select
            id="day"
            class="form-control v-select-custom"
            label="text"
            style="width: 100%"
            v-model="day"
            :reduce="item => item.value"
            :options="dayOptions"
            placeholder="Select day to schedule"
            :disabled="disableFields"
          >
          </v-select>
        </b-form-group>
      </div>
      <b-form-group label-for="body" label-class="form-label">
        <label>Body - <a href="#" class="text-primary" @click.prevent="openBookmarkDetails">Bookmark Details</a></label>
        <editor
          :disabled="disableFields"
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | mybutton | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',

            setup: function(editor) {
              /* Menu items are recreated when the menu is closed and opened, so we need
       a variable to store the toggle menu item state. */
              var toggleState = false;

              /* example, adding a toolbar menu button */
              editor.ui.registry.addMenuButton('mybutton', {
                text: 'Bookmarks',
                fetch: function(callback) {
                  var items = EMAIL_BOOKMARKS[emailAbbr].map(object => ({
                    ...object,
                    onAction: function() {
                      editor.insertContent(`&nbsp;${object.value}`);
                    }
                  }));

                  callback(items);
                }
              });
            }
          }"
          v-model="body"
          placeholder="Enter body for email"
        />
      </b-form-group>

      <b-btn
        variant="primary"
        :disabled="isFormLoading"
        style="width: 150px"
        type="submit"
        class="btn-normal"
        v-if="!disableFields"
      >
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else> {{ $t('update') }}</span>
      </b-btn>
    </b-form>
    <bookmark-detail-modal :show-modal="showBookmarkDetails" @close="hideBookmarkDetails" :abbr="emailAbbr" />
  </div>
</template>

<script>
import Page from '@/components/Page.vue';
import { EMAIL_BOOKMARKS, WEEK_DAYS_OPTIONS, EMAIL_TYPES, USER_ROLE_TYPES } from '@/common/constants';
import { mapActions, mapGetters } from 'vuex';
import BookmarkDetailModal from './BookmarkDetailModal.vue';
import Editor from '@tinymce/tinymce-vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/en';
import 'vue2-datepicker/locale/de';
import moment from 'moment';
import vSelect from 'vue-select';
import PageHeader from '@/components/Ui/PageHeader.vue';
export default {
  name: 'UpdateEmail',
  extends: Page,
  components: {
    Editor,
    BookmarkDetailModal,
    DatePicker,
    vSelect,
    PageHeader
  },

  data() {
    return {
      title: 'Email Configuration',
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,
      enabled: true,
      subject: null,
      emailTitle: null,
      time: moment().format('HH:mm'),
      body: null,
      emailAbbr: '',
      isEmailLoading: false,
      day: null,
      isLoading: false,
      EMAIL_BOOKMARKS,
      showBookmarkDetails: false,
      dayOptions: WEEK_DAYS_OPTIONS,
      type: '',
      EMAIL_TYPES,
      USER_ROLE_TYPES
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isFormLoading() {
      return this.isLoading || this.isEmailLoading;
    },
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Manage Emails', to: { name: 'manage-emails' } },
        { text: 'Configuration', active: true }
      ];
    },
    disableFields() {
      return (
        (this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL && this.$route.params.type === EMAIL_TYPES.SYSTEM) ||
        (this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL &&
          this.$route.params.type === EMAIL_TYPES.SYSTEM &&
          ['reminder_payment_dues', 'payment_plan_for_student', 'close_chats'].includes(this.emailAbbr))
      );
    }
  },
  methods: {
    ...mapActions('emailConfig', ['updateEmail', 'getEmail']),
    openBookmarkDetails() {
      this.showBookmarkDetails = true;
    },
    hideBookmarkDetails() {
      this.showBookmarkDetails = false;
    },
    async fetchEmail() {
      this.isEmailLoading = true;

      try {
        const res = await this.getEmail(this.$route.params.id);
        this.emailTitle = res.data.title;
        this.enabled = res.data.enabled;
        this.emailAbbr = res.data.email_abbr;
        this.subject = res.data.subject;
        this.body = res.data.body;
        this.type = res.data.type;
        this.day = res.data.periodic_task ? res.data.periodic_task.crontab.day_of_week : null;
        if (res.data.periodic_task) {
          this.time = moment(`${res.data.periodic_task.crontab.hour}.${res.data.periodic_task.crontab.minute}`, [
            'HH.mm'
          ]).format('hh:mm a');
        }
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        this.$router.push({ name: 'manage-emails' });
      }
      this.isEmailLoading = false;
    },

    async onSubmit() {
      this.isLoading = true;
      let splitTime = moment(this.time, ['h:mm A']).format('HH:mm');
      try {
        let scheduled_at = {
          minute: splitTime.split(':')[1],
          hour: splitTime.split(':')[0],
          day_of_week: this.day,
          day_of_month: '*',
          month_of_year: '*'
        };
        const data = {
          ...(this.type === EMAIL_TYPES.RECURRING && {
            scheduled_at
          }),
          enabled: this.enabled,
          subject: this.subject,
          body: this.body
        };
        if (this.$route.params.id) {
          await this.updateEmail({ id: this.$route.params.id, data });
          this.makeToast({ variant: 'success', msg: 'Email Updated!' });
        }

        setTimeout(
          () =>
            this.$router.push({
              name:
                this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL ? 'manage-emails' : 'sa-manage-system-emails'
            }),
          250
        );
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    }
  },

  async mounted() {
    if (this.$route.params.id) {
      this.fetchEmail();
    }
  }
};
</script>

<style></style>
