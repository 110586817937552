<template>
  <div>
    <page-header :title="title" :container-class="containerClass" :breadcrumb="breadcrumb" />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Products"
                    v-model="searchTerm"
                    @input="onSearch"
                  />

                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <div class="col-lg-6 mx-0 px-0"></div>
                <!-- TODO: Add filters here. (if needed) -->
                <!-- <b-dropdown class="ml-lg-auto" :text="$t('All Topics')" variant="link text-black-70" right>
                  <b-dropdown-item active>All Topics</b-dropdown-item>
                  <b-dropdown-item>My Topics</b-dropdown-item>
                </b-dropdown>

                <b-dropdown class="mr-8pt" :text="$t('Newest')" variant="link text-black-70" right>
                  <b-dropdown-item active>Newest</b-dropdown-item>
                  <b-dropdown-item>Unanswered</b-dropdown-item>
                </b-dropdown> -->
                <b-btn class="ml-2" @click.prevent="openLinkModal" exact variant="primary" v-text="'Link Product'" />
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="allLinkedProducts"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(product_title)="data">
              <div class="d-flex align-items-center">
                <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" class="mr-2">
                  <b-img
                    :src="data.item.product_url"
                    class="img-fluid"
                    width="40"
                    alt="Logo"
                    v-if="data.item.product_url"
                  />
                  <i class="fas fa-university fa-lg" v-else></i>
                </fmv-avatar>

                {{ data.item.product_title }}
              </div>
            </template>

            <template #cell(price)="data"> {{ $n(data.item.price, 'currency', 'en-US') }}</template>

            <template #cell(quantity)="data"> {{ data.item.quantity }}</template>
            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <a
                href="#"
                @click.prevent="unlinkProduct(data.item.id)"
                v-b-popover.hover.right
                :title="'Unlink Product'"
              >
                <i class="material-icons text-danger">close</i>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalProducts"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="schools-table"
            />
          </div>
        </div>
      </div>
    </div>
    <link-modal
      :show-modal="showLinkModal"
      :productOptions="allProducts"
      :program="parseInt($route.query.program)"
      @successfull="hideSucessLink"
      @close="hideLinkModal"
    />
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import MdIcon from '@/components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '@/common/constants';
import LinkModal from './LinkModal.vue';
import { FmvAvatar } from 'fmv-avatar';
export default {
  components: { MdIcon, PageHeader, Pagination, LinkModal, FmvAvatar },
  extends: Page,

  data() {
    return {
      title: 'Linked Products',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalProducts: 0,
      searchTerm: '',
      allProducts: [],
      allLinkedProducts: [],
      showLinkModal: false,
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Programs', to: { name: 'manage-programs' } },
        ...(this.$route.params.name ? [{ text: this.$route.params.name, active: true }] : []),
        { text: 'Manage Linked Products', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'product_title', label: 'Product' },
        { key: 'price', label: 'Price' },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('products', [
      'linkProdWithProgram',
      'getProgramLinkedProducts',
      'getAllProducts',
      'unlinkProdFromProgram',
    ]),
    async fetchProducts() {
      this.isLoading = true;

      const response = await this.getAllProducts({});
      this.allProducts = response.data;
      this.isLoading = false;
    },

    openLinkModal() {
      this.showLinkModal = true;
    },
    hideLinkModal() {
      this.showLinkModal = false;
    },
    hideSucessLink() {
      this.showLinkModal = false;
      this.fetchLinkedProducts();
    },
    async fetchLinkedProducts(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getProgramLinkedProducts({
        limit: this.perPage,
        program_id: this.$route.query.program,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.allLinkedProducts = response.data.results;
      this.currentPage = pageNum;
      this.totalProducts = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchLinkedProducts(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchLinkedProducts();
    },

    onSearch() {
      this.debouncedSearchLinkedProducts(this);
    },

    debouncedSearchLinkedProducts: debounce((vm) => {
      vm.fetchLinkedProducts();
    }, 500),

    async unlinkProduct(productId) {
      this.isLoading = true;
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to unlink the selected product.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.unlinkProdFromProgram({ program: this.$route.query.program, product: productId });
          this.fetchLinkedProducts();
          this.makeToast({ variant: 'success', msg: 'Product unlinked successfully!' });
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        this.isLoading = false;
      }
    },
  },

  async mounted() {
    this.fetchProducts();
    this.fetchLinkedProducts();
  },
};
</script>
