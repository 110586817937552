<template>
  <div :class="containerClass">
    <page-header
      :title="title"
      :container-class="containerClass"
      :breadcrumb="breadcrumb"
      info-text="Integrate our inquiry widget on your website."
    />

    <div class="page-section">
      <div :class="containerClass">
        <p>
          Easily integrate our inquiry widget on your website for getting program inquiries directly from your school's
          website. Follow the steps given below for the integration.
        </p>

        <p><strong>Step 1:</strong> Copy and add the following scripts in your website.</p>

        <div class="card card-body bg-dark">
          <div class="d-flex justify-content-end">
            <b-btn v-if="isScriptCopied" pill size="sm" variant="success"
              ><md-icon class="mr-2">check</md-icon> Copied</b-btn
            >
            <b-btn @click.prevent="copyScriptCode" size="sm" pill v-else v-b-popover.hover.top="'Copy Code'">
              Copy
            </b-btn>
          </div>
          <div class="mt-0 mb-4">
            <code class="highlight html bg-dark hljs xml">{{ scriptCode }}</code>
          </div>
        </div>

        <p>
          <strong>Step 2:</strong> Add the following tag in your html file. Make sure to paste it as is. Widget's button
          colors are customizable using <code>widget-background</code> and <code>icon-color</code> optional props.
        </p>

        <div class="card card-body bg-dark">
          <div class="d-flex justify-content-end">
            <b-btn v-if="isBodyCopied" pill size="sm" variant="success"
              ><md-icon class="mr-2">check</md-icon> Copied</b-btn
            >
            <b-btn @click.prevent="copyBodyCode" size="sm" pill v-else v-b-popover.hover.top="'Copy Code'">
              Copy
            </b-btn>
          </div>
          <div class="mt-0 mb-4">
            <code class="highlight html bg-dark hljs xml">{{ bodyCode }}</code>
          </div>
        </div>

        <p>
          <strong>That's it!</strong> You should see a floating chat icon on the bottom right of your page. Here's an
          example page for the complete code given above.
        </p>

        <div class="card card-body bg-dark">
          <div class="d-flex justify-content-end">
            <b-btn v-if="isIndexFileCopied" pill size="sm" variant="success"
              ><md-icon class="mr-2">check</md-icon> Copied</b-btn
            >
            <b-btn @click.prevent="copyIndexFileCode" size="sm" pill v-else v-b-popover.hover.top="'Copy Code'">
              Copy
            </b-btn>
          </div>
          <div class="mt-0 mb-4">
            <code class="highlight html bg-dark hljs xml">{{ indexFileCode }}</code>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-useless-escape */
import Page from '@/components/Page.vue';
import { mapGetters } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
export default {
  name: 'InquiryWidgetDetails',
  extends: Page,
  components: { PageHeader },
  data() {
    return {
      title: 'Manage Branding',
      isHeaderCopied: false,
      isBodyCopied: false,
      isScriptCopied: false,
      isIndexFileCopied: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [{ text: 'Program Inquiry Widget', active: true }];
    },

    headerCode() {
      return `<link href="${process.env.VUE_APP_WC_URL}/css/app.css" rel="stylesheet">`;
    },
    bodyCode() {
      return `<inquiry-widget sch-key="${this.getLoggedInUser.linked_entity.id}" widget-background="#214e6f" icon-color="#ffffff"></inquiry-widget>`;
    },
    scriptCode() {
      return `<script src="https://unpkg.com/vue@2"><\/script>
<script src="${process.env.VUE_APP_WC_URL}/inquiry-widget.min.js"><\/script>`;
    },
    indexFileCode() {
      return `<!DOCTYPE html>
  <html>
  <body>
    ${this.bodyCode}

    <!-- widget source js -->
    <script src="https://unpkg.com/vue@2"><\/script>
    <script src="${process.env.VUE_APP_WC_URL}/inquiry-widget.min.js"><\/script>
  </body>
</html>`;
    },
  },
  methods: {
    async copyBodyCode() {
      try {
        await navigator.clipboard.writeText(this.bodyCode);
        this.isBodyCopied = true;
        setTimeout(() => (this.isBodyCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
    async copyScriptCode() {
      try {
        await navigator.clipboard.writeText(this.scriptCode);
        this.isScriptCopied = true;
        setTimeout(() => (this.isScriptCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
    async copyIndexFileCode() {
      try {
        await navigator.clipboard.writeText(this.indexFileCode);
        this.isIndexFileCopied = true;
        setTimeout(() => (this.isIndexFileCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
  },
  async mounted() {},
};
</script>

<style>
</style>