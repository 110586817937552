<template>
  <div class="card card-lg overlay--primary-dodger-blue mb-2">
    <div class="card-body d-flex flex-column">
      <div class="d-flex align-items-center">
        <div class="col-1 col-md-1">
          <i class="fas fa-bars icon-16pt mr-8pt"></i>
        </div>
        <div class="col-4 col-md-4">
          <span>{{ text }}</span>
        </div>
        <div class="col-1 col-md-1 icon-align mr-8pt">
          <md-icon>{{ icon }}</md-icon>
        </div>
        <div class="col-4 col-md-5">
          <span>{{ url }}</span>
        </div>
        <div class="mr-2">
          <a href="#" @click.prevent="updateItem" v-b-popover.hover.right :title="'Edit Item'">
            <i class="material-icons icon-18pt">edit</i>
          </a>
        </div>
        <div>
          <a href="#" @click.prevent="removeItem" v-b-popover.hover.right :title="'Remove Item'">
            <i class="material-icons icon-18pt">close</i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UrlCard',
  props: {
    text: { type: String, default: '' },
    url: { type: String, default: '' },
    icon: { type: String, default: '' },
    index: { type: Number, default: null },
  },
  data() {
    return {};
  },
  methods: {
    async removeItem() {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `Please confirm that you want to remove the selected item. The item will no longer appear on the school public profile and all existing responses will be removed.`,
        {
          title: 'Are you sure?',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (isConfirmed) {
        this.$emit('removeItem', this.index);
      }
    },
    updateItem() {
      this.$emit('updateItem', this.index);
    },
  },
};
</script>

<style>
</style>
