<template>
  <b-modal
    ref="addUrlModal"
    hide-footer
    :title="index ? 'Update Item' : 'Add Item'"
    centered
    lazy
    @hide="hideModal"
    size="md"
  >
    <b-form-group label="Icon" label-for="icons" label-class="form-label">
      <v-select
        id="type"
        class="form-control v-select-custom"
        label="title"
        v-model="icon"
        placeholder="Select Icon "
        :options="iconOptions"
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!icon" v-bind="attributes" v-on="events" />
        </template>

        <template slot="option" slot-scope="iconOptions">
          <div>
            <md-icon>{{ iconOptions.title }}</md-icon>
          </div>
        </template>
        <template slot="selected-option" slot-scope="icon">
          <div>
            <md-icon>{{ icon.title }}</md-icon>
          </div>
        </template>
      </v-select>
      <b-form-invalid-feedback :state="!$v.icon.required && $v.icon.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Text" label-for="text" label-class="form-label">
      <b-form-input id="text" placeholder="Enter Item's Text" v-model="text" maxlength="80" />
      <b-form-invalid-feedback :state="!$v.text.required && $v.text.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Link" label-for="url" label-class="form-label">
      <b-form-input id="url" placeholder="Enter Item's Link" v-model="url" />
      <b-form-invalid-feedback :state="!$v.url.required && $v.url.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <div class="d-flex justify-content-end">
      <b-btn
        variant="primary"
        class="ml-2"
        style="width: 70px"
        @click.prevent="submitData"
        href="#"
        data-toggle="modal"
      >
        <span> {{ this.index ? 'Update' : 'Add' }} </span>
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import vSelect from 'vue-select';
export default {
  name: 'AddUrlModal',
  props: {
    showModal: { type: Boolean, default: false },
    index: { type: Number, default: null },
  },
  components: { vSelect },

  data() {
    return {
      icon: '',
      iconOptions: [
        'link',
        'phonelink',
        'share',
        'account_balance_wallet',
        'add_call',
        'add_link',
        'drafts',
        'error_outline',
        'explore',
        'favorite',
        'list',
        'mail_outline',
        'schedule',
        'warning',
        'settings',
        'school',
        'priority_high',
        'public',
        'place',
        'new_releases',
      ],
      text: '',
      url: '',
    };
  },
  validations() {
    return {
      icon: { required },
      text: { required },
      url: { required },
    };
  },
  methods: {
    ...mapActions('school', ['createCustomNavbarItem', 'getCustomNavbarItem', 'updateCustomNavbarItem']),
    resetData() {
      this.icon = null;
      this.text = null;
      this.url = null;
    },
    async submitData() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const data = {
          icon: this.icon,
          text: this.text,
          link: this.url,
        };
        try {
          if (this.index) {
            await this.updateCustomNavbarItem({
              id: this.index,
              data,
            });
            this.makeToast({ variant: 'success', msg: 'Item Updated' });
          } else {
            await this.createCustomNavbarItem(data);

            this.makeToast({ variant: 'success', msg: 'Item Added' });
          }
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
        this.hideUpdateClose();
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
    },

    hideModal() {
      this.$emit('close');
    },

    hideUpdateClose() {
      this.$emit('closeUpdate');

      this.resetData();
      this.$refs.addUrlModal.hide();
    },

    hideModalManual() {
      this.$refs.addUrlModal.hide();
      this.hideModal();
    },
  },
  watch: {
    async showModal(value) {
      if (value) {
        this.$refs.addUrlModal.show();
        if (this.index) {
          try {
            const resp = await this.getCustomNavbarItem(this.index);
            this.text = resp.data.text;
            this.url = resp.data.link;
            this.icon = resp.data.icon;
          } catch (err) {
            this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
          }
        } else {
          this.resetData();
        }
      }
    },
  },
};
</script>

<style>
</style>