<template>
  <b-modal ref="commentModal" hide-footer title="Notes" scrollable centered lazy @hide="hideModal" size="md">
    <p>
      {{ comment }}
    </p>
  </b-modal>
</template>

<script>
export default {
  name: 'CommentModal',

  props: {
    showModal: { type: Boolean, default: false },
    comment: { type: String, default: () => '' }
  },

  methods: {
    async hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.commentModal.hide();
      this.hideModal();
    }
  },
  watch: {
    showModal(value) {
      if (value) {
        this.$refs.commentModal.show();
      }
    }
  },
  mounted() {}
};
</script>

<style></style>
