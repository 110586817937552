<template>
  <div :class="containerClass" class="" style="margin-top: -15px">
    <h2 class="mt-4">Feature Updates</h2>
    <div v-if="isLoading && !featureUpdates.length">
      <div class="border-left-1 pl-32pt pb-8pt ml-3" v-for="update in [1, 2, 3]" :key="update">
        <div class="d-flex align-items-center page-num-container">
          <div class="page-num">
            <md-icon>lightbulb_outline</md-icon>
          </div>
          <b-skeleton width="20%"></b-skeleton>
        </div>
        <div class="text-70 feature-update-description mb-lg-32pt">
          <b-skeleton></b-skeleton>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton></b-skeleton>
        </div>
      </div>
    </div>
    <div v-else-if="!featureUpdates.length" class="card p-3 text-center" style="margin-top: 60px">
      <md-icon class="mb-2" style="font-size: 30px">info</md-icon>

      No latest feature update
    </div>
    <div v-else>
      <div class="border-left-1 pl-32pt pb-8pt ml-3" v-for="(update, index) in featureUpdates" :key="update.id">
        <div class="feature">
          <div class="d-flex align-items-center page-num-container sticky-header">
            <div class="page-num" style="margin-top: -15px">
              <md-icon>lightbulb_outline</md-icon>
            </div>
            <div class="p-2">
              <router-link :to="{ name: 'feature-update-details', params: { id: update.id } }">
                <h4 class="">{{ update.title }}</h4>
              </router-link>
              {{ formatDateTime(update.created_at) }}
            </div>
          </div>

          <div
            v-if="details ? detailsIndex !== index : true"
            class="text-70 feature-update-description px-2"
            style="font-size: 16px"
            v-safe-html="update.summary"
          ></div>
          <div
            style="font-size: 16px"
            v-if="details"
            class="text-70 px-2 feature-update-description"
            v-safe-html="update.details"
          ></div>
          <span
            v-if="details ? false : update.details"
            @click.prevent="showDetails(update, index)"
            style="cursor: pointer"
            class="text-primary px-2"
            >View more</span
          >
        </div>
      </div>
      <div style="margin-left: 200px" class="mb-4" v-if="featureUpdates.length < totalFeatureUpdates">
        <b-btn @click.prevent="fetchFeatureUpdates(currentPage + 1)" style="width: 200px">
          <div class="text-center" v-if="isLoading">
            <i class="fas fa-circle-notch fa-spin" />
          </div>
          <span v-else> View More Updates </span>
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Page from '@/components/Page.vue';
import { mapActions } from 'vuex';
import { DEFAULT_PAGE_SIZE } from '@/common/constants';
import { formatDateTime } from '@/common/utils';

export default {
  components: {},
  extends: Page,

  data() {
    return {
      title: 'Feature Updates',
      featureUpdates: [],
      totalFeatureUpdates: 0,
      isLoading: true,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      details: false,
      detailsIndex: null,
    };
  },

  methods: {
    ...mapActions('notifications', ['getAllFeaturesUpdates', 'markAllRead']),

    formatDateTime,

    async fetchFeatureUpdates(pageNum = 1, params = {}) {
      this.isLoading = true;

      const response = await this.getAllFeaturesUpdates({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...params,
      });
      this.featureUpdates = this.featureUpdates.concat(response.data.results);

      this.currentPage = pageNum;
      this.totalFeatureUpdates = response.data.count;

      this.isLoading = false;
    },
    showDetails(update, index) {
      if (update.details) {
        this.details = true;
        this.detailsIndex = index;
      } else {
        this.details = false;
      }
    },
    async markAllNotificationsRead() {
      await this.markAllRead({ notification_type__type_abbreviation: 'app_feature_update' });
    },
  },
  mounted() {
    this.fetchFeatureUpdates();
    this.markAllNotificationsRead();
  },
};
</script>

<style lang="scss" scoped>
.feature {
  position: relative !important;
  min-height: 150px !important;
}
.sticky-header {
  position: sticky !important;
  top: 65px !important;
  background: #fff !important;
  z-index: 1;
  padding-top: 6px;
}
</style>