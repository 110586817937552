<template>
  <b-modal
    ref="addPaymentModal"
    hide-footer
    title="Request Payment Refund"
    scrollable
    centered
    lazy
    @hide="hideModal"
    size="lg"
  >
    <div class="my-5" v-if="isSubmit">
      <div class="container">
        <b-skeleton-wrapper :loading="isLoading">
          <template #loading>
            <div class="d-flex justify-content-center">
              <div class="col-md-8">
                <div class="text-center">
                  <div class="d-flex justify-content-center my-4">
                    <b-skeleton type="avatar" style="font-size: 1.1rem"></b-skeleton>
                  </div>
                  <div class="d-flex justify-content-center">
                    <b-skeleton width="50%" class="mb-4"></b-skeleton>
                  </div>
                  <p class="lead text-white-50">
                    <b-skeleton></b-skeleton>
                  </p>
                  <p class="lead text-white-50">
                    <b-skeleton></b-skeleton>
                  </p>
                  <p class="lead text-white-50">
                    <b-skeleton width="75%"></b-skeleton>
                  </p>
                  <div class="d-flex justify-content-center">
                    <b-skeleton type="button" width="160px"></b-skeleton>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="d-flex justify-content-center">
            <div class="col-md-8">
              <div class="text-center">
                <i v-if="submitted" class="fas fa-check-circle fa-lg text-success mb-4" style="font-size: 3rem" />
                <i v-else class="fas fa-times-circle fa-lg text-danger mb-4" style="font-size: 3rem" />
                <h4>
                  {{ submitted ? 'Your refund request has been submitted successfully' : 'Request submission Failed!' }}
                </h4>

                <p class="text-muted" v-if="submitted">
                  You will be informed about the refund status within 48 working hours. For any further query feel free
                  to contact us at
                  <a class="text-primary" href="mailto:info@jointransition.com">info@jointransition.com</a>
                </p>
                <p class="text-muted" v-else>
                  Your request cannot be initiated. Please try again later or contact us at
                  <a class="text-primary" href="mailto:info@jointransition.com">info@jointransition.com</a>
                </p>
              </div>
              <div class="d-flex justify-content-center">
                <b-button @click.prevent="hideModalManual" variant="primary">Close</b-button>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>
    <b-form v-else class="col-md-12 px-0 pt-0" @submit.prevent="onSubmit">
      <b-alert variant="soft-light px-12pt text-center" show>
        <b>Important:</b> You can request a refund for free once each month. After the first time, due to costs from
        payment processors, we have to charge $15 for each refund. This applies for up to five refund requests in a
        month. We recommend trying to settle any payment issues directly to avoid extra charges.
      </b-alert>

      <b-form-group label="Amount" label-for="amount" label-class="form-label">
        <currency-input
          v-model="amount"
          required
          class="form-control"
          placeholder="Enter amount"
          :allow-negative="false"
        />
      </b-form-group>

      <b-form-group label="Reason" label-for="reason" label-class="form-label">
        <b-form-textarea id="reason" placeholder="Enter reason" :rows="4" required v-model="reason" />
      </b-form-group>

      <div class="mb-4" v-if="plans.length">
        <div>
          <h2 class="form-label">Payment Plans</h2>
        </div>

        <b-table
          :fields="plansTableColumns"
          :items="plans"
          :busy="arePlansLoading"
          head-variant="light"
          hover
          responsive
          no-local-sorting
        >
          <template #cell(status)="data">
            <b-badge
              variant="none"
              :class="
                (data.item.status === STUDENT_PAYMENT_TYPES.CANCELLED && data.item.status_paid) ||
                (data.item.status === STUDENT_PAYMENT_TYPES.PAID && data.item.status_paid)
                  ? 'border border-success text-success'
                  : data.item.status === STUDENT_PAYMENT_TYPES.CANCELLED && !data.item.status_paid
                  ? 'border border-warning text-warning'
                  : 'border border-danger text-danger'
              "
            >
              <strong>{{
                (data.item.status === STUDENT_PAYMENT_TYPES.CANCELLED && data.item.status_paid) ||
                (data.item.status === STUDENT_PAYMENT_TYPES.PAID && data.item.status_paid)
                  ? 'Paid'
                  : data.item.status === STUDENT_PAYMENT_TYPES.CANCELLED && !data.item.status_paid
                  ? 'Cancelled'
                  : 'Unpaid'
              }}</strong>
            </b-badge>
          </template>

          <template #cell(amount)="data"> {{ $n(data.item.amount, 'currency', 'en-US') }} </template>

          <template #cell(date)="data"> {{ formatFullDate(data.item.date) }} </template>

          <template #cell(date_paid)="data"> {{ formatFullDate(data.item.date_paid) }} </template>
        </b-table>
      </div>

      <div class="mb-4" v-if="payments.length">
        <div>
          <h2 class="form-label">Payment Log</h2>
        </div>

        <b-table
          :fields="tableColumns"
          :items="payments"
          :busy="arePlansLoading"
          head-variant="light"
          hover
          responsive
          no-local-sorting
        >
          <template #cell(type)="data"> {{ $t(`transactionTypes.${data.item.type}`) }} </template>

          <template #cell(amount)="data">{{ formatAmount(data.item.amount) }}</template>

          <template #cell(created_at)="data"> {{ formatFullDate(data.item.created_at) }} </template>
        </b-table>
      </div>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ 'Request' }}</span>
      </b-btn>
    </b-form>
  </b-modal>
</template>

<script>
import { get } from 'lodash';
import { mapActions } from 'vuex';
import { formatFullDate } from '@/common/utils';
import { STUDENT_PAYMENT_TYPES } from '@/common/constants';
import AmountFormatter from '@/mixins/amount-formatter';

export default {
  name: 'ReqRefundPaymentModal',
  mixins: [AmountFormatter],

  props: {
    showModal: { type: Boolean, default: false },
    payment: { type: Object, default: null },
  },

  data() {
    return {
      amount: null,
      reason: '',
      payments: [],
      plans: [],
      isLoading: false,
      arePlansLoading: false,
      isSubmit: false,
      submitted: false,
      STUDENT_PAYMENT_TYPES,
    };
  },

  computed: {
    isFormLoading() {
      return this.isLoading;
    },
    plansTableColumns() {
      return [
        { key: 'status', label: 'status' },
        { key: 'amount', label: 'Amount' },
        { key: 'date', label: 'Date' },
        { key: 'date_paid', label: 'Due Date' },
      ];
    },

    tableColumns() {
      return [
        { key: 'type', label: 'Type' },
        { key: 'description', label: 'Description' },
        { key: 'amount', label: 'Amount' },
        { key: 'created_at', label: 'Date' },
      ];
    },
  },
  methods: {
    ...mapActions('refunds', ['createRefundReq']),
    ...mapActions('payoutAcc', ['getPayments', 'getPlansToRefund']),
    get,
    formatFullDate,
    resetData() {
      this.amount = null;
      this.reason = '';
      this.isSubmit = false;
    },

    async onSubmit() {
      this.isSubmit = true;
      this.isLoading = true;
      try {
        const data = {
          amount: this.amount,
          refund_reason: this.reason,
        };

        await this.createRefundReq({
          registration: this.payment.id,
          ...data,
        });

        this.submitted = true;
      } catch (e) {
        this.submitted = false;
      }
      this.isLoading = false;
    },

    async fetchPlans() {
      this.arePlansLoading = true;
      try {
        const response = await this.getPlansToRefund({ reg_id: this.payment.id });

        this.plans = response.data;
      } catch (error) {
        //
      }
      this.arePlansLoading = false;
    },

    async fetchPayments() {
      this.arePlansLoading = true;
      try {
        const response = await this.getPayments({ reg_id: this.payment.id });

        this.payments = response.data;
      } catch (error) {
        //
      }
      this.arePlansLoading = false;
    },

    async hideModal() {
      this.$emit('close');
      this.resetData();
    },

    hideModalManual() {
      this.$refs.addPaymentModal.hide();
      this.hideModal();
    },
  },
  watch: {
    showModal(value) {
      if (value) {
        this.$refs.addPaymentModal.show();

        this.fetchPayments();
        this.fetchPlans();
      }
    },
  },
  mounted() {},
};
</script>

<style></style>
