<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? 'Edit Question' : 'Add New Question'"
      :container-class="null"
      class="mb-16pt"
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label="Question" label-for="question" label-class="form-label">
        <b-form-input id="question" placeholder="Enter Question for registration form" v-model="question" required />
      </b-form-group>

      <b-form-group label="Field Type" label-for="type" label-class="form-label">
        <div class="custom-select-icon">
          <b-form-select
            class="custom-select-icon__select"
            v-model="type"
            :options="typeOptions"
            required
          ></b-form-select>
          <span class="material-icons custom-select-icon__icon">sort</span>
        </div>
      </b-form-group>

      <b-form-group label="Question's Placeholder" label-for="placeholder" label-class="form-label">
        <b-form-input id="placeholder" placeholder="Enter Placeholder for Question" v-model="placeholder" required />
      </b-form-group>

      <page-separator
        title="Possible Options"
        v-if="[QUES_TYPES.BOOLEAN, QUES_TYPES.SINGLE_SELECT, QUES_TYPES.MULTI_SELECT].includes(type)"
      />

      <div class="row" v-if="type === QUES_TYPES.BOOLEAN">
        <b-form-group label="Option 1" label-for="trueText" label-class="form-label" class="col-md-6">
          <b-form-input
            id="placeholder"
            placeholder="Enter a possible option for the answer"
            v-model="booleanOption[0].text"
            required
          />
        </b-form-group>
        <b-form-group label="Option 2" label-for="placeholder" label-class="form-label" class="col-md-6">
          <b-form-input
            id="placeholder"
            placeholder="Enter a possible option for the answer"
            v-model="booleanOption[1].text"
            required
          />
        </b-form-group>
      </div>
      <div v-if="type === QUES_TYPES.SINGLE_SELECT || type === QUES_TYPES.MULTI_SELECT">
        <b-row v-for="(selectOption, index) in $v.options.$each.$iter" :key="index">
          <b-form-group
            :label="`Option ${parseInt(index) + 1}`"
            label-for="option"
            label-class="form-label"
            class="col-md-10"
          >
            <b-form-input
              id="name"
              placeholder="Enter a possible option for the answer"
              v-model.trim="selectOption.text.$model"
              @input="inputOption(index)"
              :state="
                (!selectOption.text.required || !selectOption.value.isUnique) && selectOption.$dirty ? false : null
              "
            />

            <b-form-invalid-feedback v-if="!selectOption.value.isUnique">
              Please enter unique options.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback v-else>This field is required.</b-form-invalid-feedback>
          </b-form-group>
          <div class="mt-4 pl-2">
            <a class="btn btn-primary btn-md" href="#" @click.prevent="addOption(index)">
              <md-icon>add</md-icon>
            </a>
            <a
              href="#"
              class="btn btn-danger ml-2 btn-md"
              @click.prevent="removeOption(index)"
              v-if="options.length > 1"
            >
              <md-icon>close</md-icon>
            </a>
          </div>
        </b-row>
      </div>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import Page from '@/components/Page.vue';
import { requiredIf } from 'vuelidate/lib/validators';
import { QUES_TYPE, QUES_TYPES } from '@/common/constants';
import { mapActions, mapGetters } from 'vuex';
import { snakeCase, uniqBy } from 'lodash';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import PageHeader from '@/components/Ui/PageHeader.vue';

export default {
  name: 'PostQuestion',
  extends: Page,
  components: { PageSeparator, PageHeader },

  data() {
    return {
      title: this.$route.params.id ? 'Add Question' : 'Edit Question',
      question: '',
      type: null,
      typeOptions: QUES_TYPE,
      isLoading: false,
      isQuestionLoading: false,
      placeholder: '',
      trueText: 'Yes',
      falseText: 'No',
      QUES_TYPES,
      options: [{ text: '', value: '' }],
      booleanOption: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
    };
  },
  validations() {
    return {
      options: {
        required: requiredIf(() => this.type === QUES_TYPES.SINGLE_SELECT || this.type === QUES_TYPES.MULTI_SELECT),
        $each: {
          text: {
            required: requiredIf(() => this.type === QUES_TYPES.SINGLE_SELECT || this.type === QUES_TYPES.MULTI_SELECT),
          },
          value: {
            isUnique() {
              return this.options.length !== uniqBy(this.options, 'value').length ? false : true;
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isFormLoading() {
      return this.isLoading || this.isQuestionLoading;
    },
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Custom Questions', to: { name: 'manage-questions' } },
        { text: this.$route.params.id ? 'Edit Question' : 'Add Question', active: true },
      ];
    },
  },
  methods: {
    ...mapActions('registrationForm', ['createCustomQuestion', 'getQuestion', 'updateCustomQuestion']),

    inputOption(index) {
      this.options[index].value = snakeCase(this.options[index].text);
    },
    addOption(index) {
      this.options.splice(index + 1, 0, {
        text: '',
        value: '',
      });
    },

    removeOption(index) {
      this.options.splice(index, 1);
    },
    async fetchQuestion() {
      this.isQuestionLoading = true;

      try {
        const res = await this.getQuestion(this.$route.params.id);
        this.question = res.title;
        this.type = res.type;
        this.placeholder = res.placeholder;
        if (this.type === QUES_TYPES.BOOLEAN) {
          this.booleanOption = res.extra.options;
        } else if (this.type === QUES_TYPES.SINGLE_SELECT || this.type === QUES_TYPES.MULTI_SELECT) {
          this.options = res.extra.options;
        }
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isQuestionLoading = false;
    },

    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const data = {
            title: this.question,
            type: this.type,
            school: this.getLoggedInUser.linked_entity.id,
            placeholder: this.placeholder,
            ...(this.type === QUES_TYPES.FILE_TYPE && { file_type: this.file_type }),
            extra:
              this.type === QUES_TYPES.BOOLEAN
                ? { options: this.booleanOption }
                : this.type === QUES_TYPES.SINGLE_SELECT || this.type === QUES_TYPES.MULTI_SELECT
                ? { options: this.options }
                : {},
          };

          if (this.$route.params.id) {
            await this.updateCustomQuestion({ id: this.$route.params.id, data });
            this.makeToast({ variant: 'success', msg: 'Question Updated!' });
          } else {
            await this.createCustomQuestion({ data });
            this.makeToast({ variant: 'success', msg: 'Question Added!' });
          }

          setTimeout(
            () =>
              this.$router.push({
                name: 'manage-questions',
              }),
            250
          );
        } catch (err) {
          if (err.response.status === 400) {
            this.makeToast({ variant: 'danger', msg: 'Question type can not be changed!' });
          } else this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      this.fetchQuestion();
    }
  },
};
</script>

<style>
</style>
