<template>
  <b-modal ref="addPaymentModal" hide-footer title="Payment" centered lazy @hide="hideModal" size="lg">
    <b-form class="col-md-12 px-0 pt-0" @submit.prevent="onSubmit">
      <b-form-group label="Amount" label-for="amount" label-class="form-label">
        <currency-input
          v-model="amount"
          required
          class="form-control"
          placeholder="Enter amount"
          :allow-negative="false"
        />
      </b-form-group>

      <b-form-group label="Date" label-for="date" label-class="form-label">
        <date-picker
          v-model="date"
          value-type="YYYY-MM-DD"
          format="YYYY-MM-DD"
          style="width: 100%"
          id="date"
          lang="en"
          required
          placeholder="YYYY/MM/DD"
        ></date-picker>
      </b-form-group>

      <b-form-group label="Description" label-for="description" label-class="form-label">
        <b-form-textarea
          id="description"
          maxlength="50"
          placeholder="Enter description."
          :rows="4"
          required
          v-model="description"
        />
      </b-form-group>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ modalUpdate ? 'update' : 'add' }}</span>
      </b-btn>
    </b-form>
  </b-modal>
</template>

<script>
import { get } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
// import moment from 'moment-timezone';
import DatePicker from 'vue2-datepicker';
import { formatToAPIDateTime } from '../../../common/dateTimeUtils';

export default {
  name: 'AddPaymentModal',
  components: { DatePicker },

  props: {
    showModal: { type: Boolean, default: false },
    payment: { type: Object, default: null },
    modalUpdate: { type: Boolean, default: false },
  },

  data() {
    return {
      date: '',
      amount: null,
      description: '',
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    isFormLoading() {
      return this.isLoading;
    },
  },
  methods: {
    ...mapActions('student', ['createManuallyPayment']),
    ...mapActions('payoutAcc', ['updateManuallyPayment']),

    get,

    resetData() {
      this.date = '';
      this.amount = null;
      this.description = '';
    },

    async onSubmit() {
      this.isLoading = true;
      try {
        const data = {
          // id: this.index,
          created_at: formatToAPIDateTime(this.date, this.getLoggedInUser.linked_entity.time_zone),
          amount: this.amount,
          description: this.description,
        };

        if (this.modalUpdate) {
          await this.updateManuallyPayment({
            id: this.payment.id,
            data,
          });
          this.makeToast({ variant: 'success', msg: 'Payment Updated Successfully!' });
        } else {
          await this.createManuallyPayment({
            reg_id: this.payment.id,
            ...data,
          });
          this.makeToast({ variant: 'success', msg: 'Payment Created Successfully!' });
        }
      } catch (e) {
        // USE if needed
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
      this.$refs.addPaymentModal.hide();
    },

    async hideModal() {
      this.$emit('close');
      this.resetData();
    },

    hideModalManual() {
      this.$refs.addPaymentModal.hide();
      this.hideModal();
    },
  },
  watch: {
    showModal(value) {
      if (value) {
        if (this.modalUpdate) {
          this.description = this.payment.description || '';
          this.amount = this.payment.amount || '';
          this.date = this.payment.created_at || '';
        }
        this.$refs.addPaymentModal.show();
      }
    },
  },
  mounted() {},
};
</script>

<style>
</style>