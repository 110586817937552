<template>
  <div :class="containerClass">
    <page-header
      title="Custom Navbar Menu"
      :container-class="null"
      class="mb-16pt"
      :info-text="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL ? `Tailor your website's navigation menu.` : ''"
    />
    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <div class="d-flex flex-wrap justify-content-end">
        <b-btn
          variant="primary"
          @click.prevent="openUrlModal"
          style="width: 150px"
          type="submit"
          class="btn-normal mb-2"
        >
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>Add</span>
        </b-btn>
      </div>

      <div class="row mb-lg-12pt" v-if="items.length">
        <draggable class="col-sm-12 draggable-cursor" @start="dragging = true" @end="updateOrder" :list="items">
          <div v-for="item in items" :key="item.id">
            <url-card
              :text="item.text"
              :url="item.link"
              :icon="item.icon"
              :index="item.id"
              @removeItem="removeItem"
              @updateItem="updateItem"
            />
          </div>
        </draggable>
      </div>

      <b-form-invalid-feedback
        class="mb-lg-12pt"
        v-if="(!$v.items.required || !$v.items.minLength) && $v.items.$dirty"
        :state="(!$v.items.required || !$v.items.minLength) && $v.items.$dirty ? false : null"
        >There isn't any item's added. Please add some items's.</b-form-invalid-feedback
      >
      <div class="mb-lg-12pt" v-else-if="!items.length">
        <i>There isn't any item's added.</i>
      </div>
    </b-form>

    <add-url-modal
      :show-modal="showUrlModal"
      :index="index"
      @update="updateItemList"
      @close="hideUrlModal"
      @closeUpdate="hideUpdateUrlModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { map } from 'lodash';
import Page from '@/components/Page.vue';
import AddUrlModal from './AddUrlModal.vue';
import UrlCard from './UrlCard.vue';
import { required, minLength } from 'vuelidate/lib/validators';
import draggable from 'vuedraggable';
import PageHeader from '@/components/Ui/PageHeader.vue';
import { USER_ROLE_TYPES } from '@/common/constants';
export default {
  extends: Page,
  components: {
    AddUrlModal,
    UrlCard,
    draggable,
    PageHeader,
  },
  data() {
    return {
      title: 'Custom Navbar Menu',
      items: [],
      isLoading: false,
      dragging: false,
      showUrlModal: false,
      areItemsLoading: false,
      index: null,
      USER_ROLE_TYPES,
    };
  },
  validations() {
    return {
      items: { required, minLength: minLength(1) },
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },
  methods: {
    ...mapActions('school', ['getAllCustomNavbarItem', 'deleteCustomNavbarItem', 'updateCustomNavbarItemOrder']),

    async updateOrder() {
      this.dragging = false;
      const data = {
        custom_navbar_menu: map(this.items, (id, order_num) => ({
          id: id.id,
          order_num: order_num + 1,
        })),
      };
      try {
        await this.updateCustomNavbarItemOrder(data);
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async fetchItems() {
      this.areItemsLoading = true;
      try {
        const resp = await this.getAllCustomNavbarItem({});
        this.items = resp.data;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areItemsLoading = false;
    },
    openUrlModal() {
      this.showUrlModal = true;
      this.index = null;
    },
    hideUrlModal() {
      this.showUrlModal = false;
    },
    hideUpdateUrlModal() {
      this.showUrlModal = false;
      this.fetchItems();
    },
    updateItem(index) {
      this.showUrlModal = true;
      this.index = index;
    },
    updateItemList() {
      this.fetchItems();
    },
    async removeItem(index) {
      if (index) {
        try {
          await this.deleteCustomNavbarItem(index);
          this.updateItemList();
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      }
    },
  },
  async mounted() {
    await this.fetchItems();
  },
};
</script>
