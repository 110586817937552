<template>
  <div>
    <page-header
      :title="title"
      :container-class="containerClass"
      info-text="Manage product purchases made by enrolled students."
    />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Products"
                    v-model="searchTerm"
                    @input="onSearch"
                  />

                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <div class="col-lg-6 mx-0 px-0">
                  <v-select
                    id="status"
                    class="form-control v-select-custom"
                    label="text"
                    v-model="filters.deliveryStatus"
                    :reduce="(element) => element.value"
                    placeholder="Filter by Delivery Status"
                    :options="allDelStatuses"
                    @input="onFilterApplied"
                  >
                    <template #search="{ attributes, events }">
                      <input class="vs__search" v-bind="attributes" v-on="events" />
                    </template>

                    <template slot="option" slot-scope="option">
                      {{ option.text }}
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.text }}
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="allProducts"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(product)="data">
              <div class="d-flex align-items-center">
                <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" class="mr-2">
                  <b-img
                    :src="data.item.product.product_url"
                    class="img-fluid"
                    width="40"
                    alt="Logo"
                    v-if="data.item.product.product_url"
                  />
                  <i class="fas fa-university fa-lg" v-else></i>
                </fmv-avatar>

                <router-link
                  :to="{ name: 'edit-product', params: { id: data.item.product.id } }"
                  class="text-primary"
                  >{{ data.item.product.product_title }}</router-link
                >
              </div></template
            >
            <template #cell(student)="data">
              <router-link
                :to="{ name: 'schl-student-profile', params: { id: data.item.student.id } }"
                class="text-primary"
                v-b-popover.top.hover="`For Program: ${data.item.program.title}`"
                >{{ data.item.student.user.first_name }} {{ data.item.student.user.last_name }}</router-link
              >
            </template>
            <template #cell(program)="data">{{ data.item.program.title }}</template>
            <template #cell(unit_price)="data"> {{ $n(data.item.unit_price, 'currency', 'en-US') }}</template>
            <template #cell(quantity)="data"> {{ $n(data.item.quantity) }}</template>
            <template #cell(amount)="data">
              {{ $n(data.item.unit_price * data.item.quantity, 'currency', 'en-US') }}</template
            >

            <template #cell(delivery_status)="data">
              <div class="custom-select-icon" style="min-width: 130px">
                <b-form-select
                  class="custom-select-icon__select"
                  v-model="data.item.delivery_status"
                  :options="options"
                  @change="changeStatus(data.item.id, data.item.delivery_status)"
                >
                  ></b-form-select
                >
                <span class="material-icons custom-select-icon__icon">sort</span>
              </div>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalProducts"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="schools-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import MdIcon from '@/components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '@/common/constants';
import { formatDateTime } from '@/common/utils';
import vSelect from 'vue-select';
import { FmvAvatar } from 'fmv-avatar';
export default {
  components: { MdIcon, PageHeader, Pagination, vSelect, FmvAvatar },
  extends: Page,

  data() {
    return {
      title: 'Manage Purchases',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalProducts: 0,
      searchTerm: '',
      allProducts: [],
      areProgramsLoading: false,
      program: null,
      options: [
        { value: null, text: 'Please Select', disabled: true },
        { value: 'pending', text: 'Pending' },
        { value: 'delivered', text: 'Delivered' },
      ],
      allDelStatuses: [
        { value: 'pending', text: 'Pending' },
        { value: 'delivered', text: 'Delivered' },
      ],
      filters: {
        deliveryStatus: null,
      },
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Manage Purchased Products', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'product', label: 'Product' },

        { key: 'student', label: 'Student' },
        { key: 'invoice', label: 'Invoice' },
        { key: 'unit_price', label: 'Price' },
        { key: 'size_purchased', label: 'Size' },
        { key: 'quantity', label: 'Quantity' },
        { key: 'amount', label: 'Amount' },
        { key: 'delivery_status', label: 'Status' },
        { key: 'created_at', label: 'Ordered At', formatter: (value) => (value ? formatDateTime(value) : value) },
      ];
    },
  },

  methods: {
    ...mapActions('products', ['getAllPurchasedProducts', 'updateProductPurchase']),
    onFilterApplied() {
      this.fetchPurchasedProducts();
    },

    async fetchPurchasedProducts(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllPurchasedProducts({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...(this.filters.deliveryStatus && { delivery_status: this.filters.deliveryStatus }),
        ...params,
      });
      this.allProducts = response.data.results;
      this.currentPage = pageNum;
      this.totalProducts = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchPurchasedProducts(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchPurchasedProducts();
    },

    onSearch() {
      this.debouncedSearchPurchasedProducts(this);
    },

    debouncedSearchPurchasedProducts: debounce((vm) => {
      vm.fetchPurchasedProducts();
    }, 500),

    async changeStatus(id, status) {
      this.isLoading = true;
      try {
        const data = { delivery_status: status };
        await this.updateProductPurchase({
          id: id,
          data,
        });
        this.fetchPurchasedProducts();
        this.makeToast({ variant: 'success', msg: 'Status updated successfully!' });
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
  },

  async mounted() {
    this.fetchPurchasedProducts();
  },
};
</script>
